import React from "react";
import {createRoot } from 'react-dom/client';

import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './index.css';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';

const holidays = ['01.01.2022','23.04.2022','01.05.2022','01.05.2022','02.05.2022','03.05.2022','04.05.2022','19.05.2022','08.07.2022','09.07.2022','10.07.2022',
'11.07.2022','12.07.2022','15.07.2022','30.08.2022','28.10.2022','29.10.2022','01.01.2023','20.04.2023','21.04.2023','22.04.2023','23.04.2023','01.05.2023','19.05.2023','27.06.2023','28.06.2023','29.06.2023','30.06.2023','01.07.2023','15.07.2023','30.08.2023','28.10.2023','29.10.2023']  	
var no_days = ['Sat', 'Sun']

class CheckDate extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {

			startDate: null,
			endDate: null,
			checkMon: true,
			checkTue: true,
			checkWed: true,
			checkThu: true,
			checkFri: true,
			checkSat: false,
			checkSun: false, 
			checkHoliday: true,
			checkRem: true
		};

		this.handleChangeEnd = this.handleChangeEnd.bind(this);
		this.handleChangeStart = this.handleChangeStart.bind(this);
	}
	handleChangeDayCheckBox = (e,day,isHoliday) => {

		if (!isHoliday){
			if (e.target.checked){
				no_days.splice(no_days.indexOf(day), 1);
			}
			else{
				no_days.push(day);
			}
		} 
		this.setState({ ...this.state,[e.target.name]:e.target.checked})		
	};
	
	


	handleChangeStart(date) {
		this.setState({
			startDate: date
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date
		});
	}

	calculateDaysLeft(startDate, endDate) {
		let weekdayCounter = 0;
	  
		if (!moment.isMoment(startDate)) startDate = moment(startDate);
		if (!moment.isMoment(endDate)) endDate = moment(endDate);
		
		while (startDate <= endDate) {
			if (!(no_days.includes(startDate.format('ddd'))) && !(this.state.checkHoliday && holidays.includes(startDate.format('DD.MM.YYYY')))){
				weekdayCounter++; 
			}			
			startDate = moment(startDate).add(1, 'days');
		}

		return weekdayCounter;
	}
  
	internshipComment(days,diff) {
		const previousInternship = 67-days;
		if (this.state.checkRem && diff<15) {return '❌ Stajın başlamasına '+diff+' gün kalmış, en az 15 gün sonra başlanabilir!'}
		else if (days<1){return 'Yukarıdaki iki tarihi değiştirerek toplam iş günü sayısını görebilirsin!'}
		else if (days<10){return '❌ '+days+' gün staj yapman mümkün değil, en az 10 gün yapabilirsin!'}
		else if (days>60){return '❌ '+days+' gün staj yapman mümkün değil, ihtiyacın olan zorunlu staj zaten 60 gün! (Gönüllü stajlar istisna)'}
		else if (days>40){return '❌ '+days+' gün staj yapman mümkün değil, en fazla 40 gün yapabilirsin! (Anlaşmalı kurumlar istisna:liste eklenecek)'}
		else {return '✓ '+days+' gün staj yapabilirsin! (Eğer toplam '+ previousInternship +' günden fazla geçmiş stajın yoksa)'}
	}

	render() {
		registerLocale('tr', tr)
		setDefaultLocale('tr');

 	
		console.log(this.state)
		console.log('nodays')
		console.log(no_days)	  
		const { startDate, endDate } = this.state;
		const diffToStart = moment(startDate).diff(moment().add(-1, 'days'), 'days');
		const daysLeft = this.calculateDaysLeft(startDate, endDate);
		const comment = this.internshipComment(daysLeft,diffToStart);
	
		
		function getWhiteSpace(text,flag){

			return (
				flag?
				<>
				&nbsp;&nbsp;{text}&nbsp;
				</>
				:
				<>
				{text}&nbsp;&nbsp;
				</>
			)
		}


		return (
		
			<div className='parent' >

			<h3>Saydım Kaç Gün Oldu v0.3</h3>
			<div>

				<label>
				<input type="checkbox"
				name='checkMon' 
				checked={this.state.checkMon}
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Mon',false)} />
					<span>Pazartesi</span>
				</label>

				<label>
				<input type="checkbox" 
				checked={this.state.checkTue}
				name='checkTue' 
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Tue',false)} />
					<span>Salı</span>
				</label>
				<label>
				<input type="checkbox"
				name='checkWed' 
				checked={this.state.checkWed}
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Wed',false)} />
					<span>Çarşamba</span>
				</label>
				<label>
				<input type="checkbox"
				name='checkThu' 
				checked={this.state.checkThu} 
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Thu',false)} />
					<span>Perşembe</span>
				</label>
				<label>
				<input type="checkbox" 
				name='checkFri'
				checked={this.state.checkFri}
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Fri',false)} />
					<span>Cuma</span>
				</label>
				<label>
				<input type="checkbox" 
				name='checkSat'
				checked={this.state.checkSat}
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Sat',false)} />
					<span>Cumartesi</span>
				</label>
				<label>
				<input type="checkbox"
				name='checkSun' 
				checked={this.state.checkSun} 
				onChange={(e)=>this.handleChangeDayCheckBox(e,'Sun',false)} />
					<span>Pazar</span>
				</label>

				<br/>
				<label>
				<input type="checkbox" 
				checked={this.state.checkHoliday}
				name='checkHoliday' 
				//it's holiday change event
				onChange={(e)=>this.handleChangeDayCheckBox(e,null,true)} />
					<span>Resmi tatilleri sayma <i>(2022, 2023 yıllarının dini ve milli bayramları içerir)</i></span>
				</label>
				<br/>
				<label>
				<input type="checkbox"
				name='checkRem' 
				checked={this.state.checkRem} 
				onChange={(e)=>this.handleChangeDayCheckBox(e,'',true)} />
					<span>Başlangıca kalan gün uyarısını aktifleştir</span>
				</label>
			</div>
			<br/>
			<div style={{display:'flex'}}>
            
          
			<DatePicker 
			dateFormat="dd.MM.yyyy"
			selected={this.state.startDate}
			onChange={this.handleChangeStart}
			withPortal
			placeholderText="Başlangıç"/>
            
			<div> 
			{getWhiteSpace('tarihinden',true) }
			</div>
             
			<DatePicker
			dateFormat="dd.MM.yyyy"
			selected={this.state.endDate}
			onChange={this.handleChangeEnd}
			withPortal
			placeholderText="bitiş"/>

			<div>
			{getWhiteSpace('tarihine',true)} 
			</div>

			<div className='daysLeft'>
			{getWhiteSpace(`saydım ${daysLeft} gün oldu.`,false)}
			</div>  
			</div>
			<div className="comment">İpucu: <i>{comment}</i></div>
			<br/><br/>
			<small>Mobil sürüm ilerleyen süreçte geliştirilebilir.</small>
			</div>    
			   
		);
	}
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement); 
root.render(<CheckDate />);

